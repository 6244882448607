import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 20,
  currentRecheckPage: 1,
  currentRecheckPageSize: 20,
  sortBy: "",
  sortOrder: "",
  search: "",
  selectedBilling: "",
  billingData: [],
  item_type: [],
  shop: [],
  status: [],
  date: [],
  from_date: "",
  to_date: "",
  estimateResponse: "",
  showAddInvoiceModal: false,
  selectedInvoice: "",
  invoiceData: [],
  invoicePrintData: [],
  receiptPrintData: [],
  estimatePrintData: [],
  estimateModal: false,
  editModal: false,
  selectedItems: [],
  invoiceModal: false,
  paymentData: [],
  slectedInvoice: "",
  BillEstimate: {
    currentPage: 1,
    currentFilter: "all",
    currentPageSize: 10,
    sortBy: "",
    sortOrder: "desc",
    search: "",
    clear: false,
    activeTab: "pending",
  },
  invoiceHistoryFilter: {
    date: [],
  },
  supportingPrintData: [],
  selectedStatus: [],
  clear: false,
  isPending: false,
  specialListData: "",
  generateBulk: false,
  
};
const billingSlice = createSlice({
  name: "staffBilling",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    resetConfig: () => initialState,
  },
});

export const { updateConfig, resetConfig } = billingSlice.actions;

export default billingSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  pendingCardStatus: "idle",
  RecheckPendingCardStatus: "idle",
  reissuePendingStatus: "idle",
  cardData: {
    Pending: [],
  },
  cardRecheckData: {
    Pending: [],
  },
  cardReissueData: {
    Pending: [],
  },
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 40,
  currentRecheckPage: 1,
  currentRecheckPageSize: 40,
  currentReissuePage: 1,
  currentReissuePageSize: 40,
  sortBy: "",
  sortOrder: "",
  search: "",
  showTable: false,
  selectedId: null,
  selectedListId: "",
  total: {
    Pending: "",
  },
  gli_id: "",
  tableData: [],
  printModal: false,
  selectedItems: [],
  printingData: [],
  printUI: false,
  clear: false,
  formData: {},
  showForm: false,
  itemTypeId: "",
  card_size: 1,
  verifyCertificateBtn: false,
  isMultiGliPrint: false,
  bulkPassModal: false,
};

export const getPrintingList = createAsyncThunk(
  "card/assigned-list",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/order/pending-queue`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getPrintingRecheckList = createAsyncThunk(
  `/employee-dashboard/recheck/assigned-to-me`,
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/recheck/pending-queue`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getPrintingReissueList = createAsyncThunk(
  `/employee-dashboard/reissue/assigned-to-me`,
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/reissue/pending-queue`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const PrintingSlice = createSlice({
  name: "printing",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: {
    // pending list
    //
    [getPrintingList.pending]: (state, action) => {
      state.pendingCardStatus = "pending";
    },
    [getPrintingList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedPending = [];
      // Add existing items with unique _id values to uniqueItems
      state.cardData.Pending.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedPending.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedPending
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedPending.push(item);
        }
      });
      if (state.search != "") {
        if (state.currentPage == 1) {
          state.cardData.Pending = newData;
        } else {
          if (
            state.cardData.Pending?.length <=
            action.payload.data.data.total_count
          ) {
            state.cardData.Pending = [...state.cardData.Pending, ...newData];
          }
        }
      } else {
        state.cardData.Pending = combinedPending;
      }
      // state.cardData.Pending = combinedPending;
      state.total.Pending = action.payload.data.data.total_count;
      state.pendingCardStatus = "fulfilled";
    },
    [getPrintingList.rejected]: (state, action) => {
      state.pendingCardStatus = "failed";
    },

    //recheck list
    [getPrintingRecheckList.pending]: (state, action) => {
      state.RecheckPendingCardStatus = "pending";
    },
    [getPrintingRecheckList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedPending = [];
      // Add existing items with unique _id values to uniqueItems
      state.cardRecheckData.Pending.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedPending.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedPending
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedPending.push(item);
        }
      });
      state.cardRecheckData.Pending = combinedPending;
      state.total.Pending = action.payload.data.data.total_count;
      state.RecheckPendingCardStatus = "fulfilled";
    },
    [getPrintingRecheckList.rejected]: (state, action) => {
      state.RecheckPendingCardStatus = "failed";
    },
    //reissue list
    [getPrintingReissueList.pending]: (state, action) => {
      state.reissuePendingStatus = "pending";
    },
    [getPrintingReissueList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedPending = [];
      // Add existing items with unique _id values to uniqueItems
      state.cardReissueData.Pending.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedPending.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedPending
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedPending.push(item);
        }
      });
      state.cardReissueData.Pending = combinedPending;
      state.total.Pending = action.payload.data.data.total_count;
      state.reissuePendingStatus = "fulfilled";
    },
    [getPrintingReissueList.rejected]: (state, action) => {
      state.reissuePendingStatus = "failed";
    },
  },
});

export const { updateConfig } = PrintingSlice.actions;

export default PrintingSlice.reducer;

import {
  Button,
  ModalLayout,
  SearchFilters,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import OrderCardListing from "../../Global/OrderCardListing";
import Style from "./printing.module.scss";
import PrintingTable from "./PrintingTable";
import PrintType from "./PrintType";
// import Front from "./CertificateTypes/VisitingCardOne/Front";
import Back from "./CertificateTypes/VisitingCardOne/Back";
import PapperFront from "./CertificateTypes/PapperCard/PapperFront";
import PapperBack from "./CertificateTypes/PapperCard/PapperBack";
import BigFront from "./CertificateTypes/BigCard/BigFront";
import usePrintingRecheck from "./usePrinting";
// import VisitingDuplex from "./CertificateTypes/VisitingCardOne/VisitingDuplex";
import PapperDuplex from "./CertificateTypes/PapperCard/PapperDuplex";
import NewFrontCard from "./CertificateTypes/VisitingCardOne/NewFrontCard";
import NewVisitingDuplex from "./CertificateTypes/VisitingCardOne/NewVisitingDuplex";
import FoldableCard from "./CertificateTypes/FoldableCard";
import MultiGliPrint from "./MultiGliPrint";

const PrintingRecheck = () => {
  const {
    visitingOneFrontPrintRef2,
    visitingOneBackPrintRef,
    papperFrontPrintRef,
    papperBackPrintRef,
    bigFrontPrintRef,
    vistingDuplexRef,
    papperDuplexRef,
    foldableCardRef,
    activeCard,
    printingState,
    cardRecheckData,
    isLoading,
    totalLength,
    lastCard,
    apiStatus,
    handleSearch,
    handleMultiClick,
    handleCardClick,
    handleModalClick,
    handleLoadMore,
    setIsLoading,
  } = usePrintingRecheck();

  return (
    <>
      <div className={Style.varifaication_main_wrap}>
        <div className="row">
          <div className={`col-4 ${Style.card_main_wrapper}`}>
            <div className="row pro-mb-4 gx-2">
              <div className="col-auto">
                <SearchFilters
                  showDateRange={false}
                  onSearchInput={handleSearch}
                  showFilters={true}
                  showActions={false}
                  SearchIcon={
                    <span className="material-symbols-outlined"> search </span>
                  }
                />
              </div>
              {/* <Button
                className={"pro-btn-primary"}
                type={"button"}
                onClick={handleMultiClick}
              >
                Multi select
              </Button> */}
            </div>
            <div className={`pro-d-flex ${Style.card_flow_wrap}`}>
              <OrderCardListing
                data={cardRecheckData}
                lastCard={lastCard}
                handleChange={handleCardClick}
                activeCard={activeCard}
                setIsLoading={setIsLoading}
                handleLoadMore={handleLoadMore}
                isLoading={isLoading}
                totalLength={totalLength}
                apiStatus={apiStatus}
              />
            </div>
          </div>
          {printingState?.showTable ? (
            <div className={`col-8 pro-pe-0 ${Style.table_main_wrapper}`}>
              <div className={`${Style.table_flow_wrapper}`}>
                <PrintingTable gliNumber={printingState?.gli_id} />
              </div>
            </div>
          ) : (
            <div className={`col-8 pro-pe-0 ${Style.table_main_wrapper}`}>
              <div className={`${Style.table_flow_wrapper}`}>
                {/* <h6 className="pro-ttl h6">Select one</h6> */}
              </div>
            </div>
          )}
          <ModalLayout
            show={printingState?.printModal}
            handleClose={handleModalClick}
            title={"Select Type"}
            closeIcon={<span className="material-symbols-outlined">close</span>}
          >
            <div className="pro-m-5">
              <PrintType
                visitingOneFrontPrintRef2={visitingOneFrontPrintRef2}
                visitingOneBackPrintRef={visitingOneBackPrintRef}
                papperFrontPrintRef={papperFrontPrintRef}
                papperBackPrintRef={papperBackPrintRef}
                bigFrontPrintRef={bigFrontPrintRef}
                vistingDuplexRef={vistingDuplexRef}
                papperDuplexRef={papperDuplexRef}
                foldableCardRef={foldableCardRef}
              />
            </div>
          </ModalLayout>

          <div className={Style.pdf_container}>
            {/* <Front visitingOneFrontPrintRef={visitingOneFrontPrintRef} /> */}
            <NewFrontCard
              visitingOneFrontPrintRef2={visitingOneFrontPrintRef2}
            />
            <Back visitingOneBackPrintRef={visitingOneBackPrintRef} />
            <PapperFront papperFrontPrintRef={papperFrontPrintRef} />
            <PapperBack papperBackPrintRef={papperBackPrintRef} />
            <BigFront bigFrontPrintRef={bigFrontPrintRef} />
            {/* <VisitingDuplex vistingDuplexRef={vistingDuplexRef} /> */}
            <NewVisitingDuplex vistingDuplexRef={vistingDuplexRef} />
            <PapperDuplex papperDuplexRef={papperDuplexRef} />
            <FoldableCard foldableCardRef={foldableCardRef} />
          </div>
        </div>
        <ModalLayout
          show={printingState?.isMultiGliPrint}
          handleClose={handleMultiClick}
          title={"Select Gli"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <MultiGliPrint />
        </ModalLayout>
      </div>
    </>
  );
};

export default PrintingRecheck;
